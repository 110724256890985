import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';
import { useRecoilValue } from 'recoil';
import { sovDataState } from '../atom/sovDataState';
import PaymentTable from './paymentTable';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';

const ModifyPaymentPage = () => {
    const { project, record, title, number } = useParams();
    const [sovData, setSovData] = useState([]);
    const [originalPayment, setOriginalPayment] = useState([]);
    const [paymentRecordNo, setPaymentRecordNo] = useState(null);
    const [loading, setLoading] = useState(true);

    console.log(sovData)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`/getSOV?project=${project}&record=${record}`);
                const res2 = await axios.get(`/getPaymentDetail?project=${project}&record=${record}`);
                if(res.data.success && res2.data.success) {
                    const sovObj = res.data.data
                    sovObj.기성차수 = number;
                    sovObj.title = title;
                    sovObj.sov = sovObj.sov.map((v)=>({...v,id:v["Ref."]}))
                    setSovData(sovObj);
                    setOriginalPayment(res2.data.data)
                    setPaymentRecordNo(res2.data.record)
                }
            } catch (error) {
                console.error('데이터 가져오기 중 오류 발생:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [project, record, number, title]);

    if (loading) {
        return (
            <div className="loadingContainer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Circles ariaLabel="loading" color="#00BFFF" height={100} width={100} />
            </div>
        );
    }

    return(
        <div className='app-content flex-column-fluid'>
            <div style={{paddingLeft:50, paddingRight:50}}>
                <div className="card mb-5 mb-xl-8">
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">{sovData.title}에 대한 {sovData.기성차수}차 기성 신청</span>
                            <span className="text-muted mt-1 fw-semibold fs-7"> 유형이 단가이면 "금회 수량/금액"에 수량을 넣어야하며, 총액이면 금액을 입력해야합니다.</span>
                        </h3>
                        <div className="card-toolbar">
                            <RouterLink className='btn btn-sm fw-bold btn-light' to= "/log-payment">
                                <i className="ki-duotone ki-left fs-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>기성조회로 돌아가기
                            </RouterLink>
                        </div>
                    </div>
                    <div className="card-body py-3">
                        {sovData && sovData.title && <PaymentTable tabledata={sovData} originalPayment={originalPayment} paymentRecordNo={paymentRecordNo}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModifyPaymentPage;
import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormDropDownList, FormUpload, FormInput } from './forms';
import { requiredValidator } from '../../../login/multistep/validators';
import { useRecoilValue } from 'recoil';
import { formStateAtom2 } from '../../../atom/formState2';

export const CreditInfo = ({ }) => {
     const types = ["A~AAA", "BB~BBB", "B", "C~CCC", "D 이하"];
     const types2 = ["A (CR-1)", "B (CR-2)", "C+ (CR-3)", "C- (CR-4)", "D 이하 (CR-5 이하)"];

     const formState2 = useRecoilValue(formStateAtom2);
     const [checkValue, setCheckValue] = React.useState(false)

     const handleValidChange = (newValid) => {
          setCheckValue(newValid);
     };


     return (
          <div className="current" data-kt-stepper-element="content">
               <div className="w-100">
                    <div className="pb-10 pb-lg-15">
                         <h2 className="fw-bold d-flex align-items-center text-dark">신용평가 정보

                              <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                   <span className="path1"></span>
                                   <span className="path2"></span>
                                   <span className="path3"></span>
                              </i>
                         </h2>
                    </div>
                    <span className="text-muted mt-1 fw-semibold fs-7"><span className='badge badge-light-info fw-semibold me-1'>이크레더블, 한국기업데이터, 나이스디엔비에서의 신용평가</span></span>
                    <div className="card mb-5 mb-xl-8">

                         <div className="card-body py-3">
                              <Field key={'신용평가등급'} id={'신용평가등급'} name={"신용평가등급"} label={'신용평가등급 범위'} component={FormDropDownList} data={types} validator={requiredValidator} />
                              <Field key={'신용평가등급1'} id={'신용평가등급1'} name={"신용평가등급1"} label={'신용평가등급'} component={FormInput} validator={requiredValidator} />
                              <Field key={'현금흐름등급'} id={'현금흐름등급'} name={"현금흐름등급"} label={'현금흐름등급 범위'} component={FormDropDownList} data={types2} validator={requiredValidator} />
                              <Field key={'현금흐름등급1'} id={'현금흐름등급1'} name={"현금흐름등급1"} label={'현금흐름등급'} component={FormInput} validator={requiredValidator} />
                              <Field
                                   key={'creditfile'} className="attach"
                                   id={'creditfile'}
                                   name={'creditfile'}
                                   label={'신용평가서 첨부'}
                                   hint={'파일을 pdf나 이미지로 올리세요'}
                                   //  defaultFiles = {defaultFiles}
                                   multiple={false}
                                   component={FormUpload} onValidChange={handleValidChange} validator={requiredValidator}
                              />

                         </div>

                    </div>
               </div>
          </div>
     );
};

import React, { useState, useEffect } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import axios from "../common/axios";


const StampTaxPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/getstamptax");
        if (res.data.success) {
          setData(res.data.data);
        }
      } catch (error) {
        console.error("데이터 가져오기 중 오류 발생:", error);
      }
    };
    fetchData();
  }, []);

  const handleButtonClick = (e) => {
    openExternalPopup(e);
  };


  return (
      <div className="app-content flex-column-fluid">
        <div className="app-container container-xxl">
          <div className="card mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  인지세 납부
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">
                  {data.length}개의 인지세를 납부해야합니다.
                </span>
              </h3>
              <div className="card-toolbar" />
            </div>
            <div className="card-body py-3">
              <div className="table-responsive">
                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  <thead>
                    <tr className="fw-bold text-muted">
                      <th className="min-w-120px">프로젝트 이름</th>
                      <th className="min-w-180px">계약번호</th>
                      <th className="min-w-280px">계약명</th>
                      <th className="min-w-280px">결정 인지세액</th>
                      <th className="min-w-280px">수급자 인지세율</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((v, index) => (
                        <tr key={index}>
                          <td className="text-dark fw-bold text-hover-primary fs-6">
                            {v.프로젝트}
                          </td>
                          <td className="text-dark fw-bold text-hover-primary fs-6">
                            {v.계약번호}
                          </td>
                          <td className="text-dark fw-bold text-hover-primary fs-6">
                            {v.계약명}
                          </td>
                          <td className="text-dark fw-bold text-hover-primary fs-6">
                            {v.인지세.toLocaleString()}원
                          </td>
                          <td className="text-dark fw-bold text-hover-primary fs-6">
                            {v.수급사업자2}%
                          </td>
                          <td className="text-end">
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                handleButtonClick(v);
                              }}
                              className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                            >
                              <i className="ki-solid ki-credit-cart text-danger fs-3">
                                {/* <span className="path1"></span>
																<span className="path2"></span> */}
                              </i>
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default StampTaxPage;

const openExternalPopup = async (obj) => {
  const {
    계약번호,
    계약명,
    계약일,
    납세번호,
    업체명,
    계약금액,
    인지세,
    수급사업자2,
    업체메일,
  } = obj;
  let total_stamp_tex_payment = 0;

  console.log(obj)

  const vendor_stamp_tex_amount = 인지세 * (수급사업자2 / 100);

  try {
    const request_stamp_tex_check_payment = await axios
      .post(`/request-stamp-tex-api/check-stamp-tex`, {
        //계약번호
        contractNo: 계약번호,
        //계약차수(Default 0)
        contractNoSeq: 0,
      })
      .then((data) => data.data);

    if (request_stamp_tex_check_payment.success) {

      console.log(request_stamp_tex_check_payment.data)
      const filter_stamp_tex_payment = request_stamp_tex_check_payment.data
        .filter((com) => com.issueBizNo === 납세번호.replaceAll("-", ""))
        .map((com) => {
          return { ...com, paymentAmount: Number(com.paymentAmount) };
        });

      total_stamp_tex_payment = _.sumBy(
        filter_stamp_tex_payment,
        "paymentAmount",
      );

      if (total_stamp_tex_payment >= vendor_stamp_tex_amount) {
        return Swal.fire({
					// title: "인지세 납부 완료!",
					title: "인지세 납부 확인!",
					// text: "인지세를 기 납부 내역이 존재합니다.",
					text: "이미 납부가 되었습니다.",
					icon: "info",
					// icon: "success",
					confirmButtonText: "OK"
				})
      }
    }
  } catch (err) {
    return Swal.fire({
      title: "문제가 있습니다!",
      text: "처리 중 에러가 발생하였습니다.",
      icon: "error",
      confirmButtonText: 'OK'
    })
  }

  try {
    const request_stamp_tex_popup_url = await axios
      .post(`/request-stamp-tex-api/pay-stamp-tex`, {
        //계약번호
        contractNo: 계약번호,
        //사업자등록번호(- 제외)
        issueBizNo: 납세번호,
        //계약명
        contractTitle: 계약명,
        //계약일(YYYYMMDD)
        contractDate: dayjs(계약일).format(
          "YYYYMMDD",
        ),
        //계약차수(Default 0)
        contractNoSeq: 0,
        //계약자명
        contractParties: 업체명,
        //계약금액
        contractAmount: 계약금액,
        //계약타입(하도급 030, 그외에는 별도 문의 필요)
        contractType: "030",
        //수입인지 결제 금액
        issueAmount: vendor_stamp_tex_amount,
        //계약자 이메일
        email: 업체메일,
      })
      .then((data) => data.data);
    if (request_stamp_tex_popup_url.success) {
      return window.open(
        request_stamp_tex_popup_url.data,
        "전자수입인지",
        "popup=yes",
      );
    }
  } catch (err) {
    return Swal.fire({
      title: "문제가 있습니다!",
      text: "처리 중 에러가 발생하였습니다.",
      icon: "error",
      confirmButtonText: 'OK'
    })
  }
};


import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div id="kt_app_footer" className="app-footer">
      <div className="app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">{currentYear}&copy;</span>
          <a href="http://www.samsungshi.com/kor/default.aspx" target="_blank" className="text-gray-800 text-hover-primary">
            삼성중공업
          </a>
            <span className="text-muted fw-semibold ms-3">
                Version: {process.env.REACT_APP_VERSION}
            </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
import * as React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, MaskedTextBox, NumericTextBox, Checkbox, ColorPicker, Switch, RadioGroup, Slider, SliderLabel, RangeSlider, TextArea, Rating } from '@progress/kendo-react-inputs';
import { DatePicker, TimePicker, DateTimePicker, DateRangePicker, DateInput } from '@progress/kendo-react-dateinputs';
import { Label, Error, Hint, FloatingLabel } from '@progress/kendo-react-labels';
import { Upload, ExternalDropZone } from '@progress/kendo-react-upload';
import { DropDownList, AutoComplete, MultiSelect, ComboBox, MultiColumnComboBox, DropDownTree } from '@progress/kendo-react-dropdowns';
import './step.css'
import { formStateAtom2 } from '../../../atom/formState2';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';
import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import krMessage2 from '../../../login/multistep/kr.json';
loadMessages(krMessage2, "ko-KR");


export const FormInput = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional, wrapperStyle,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';

  return <div className="fv-row mb-10">
    <label className="fs-6 fw-semibold form-label required">{label}</label>


    <Input className="form-control form-control-lg form-control-solid" valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
    {showValidationMessage && <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
      <div id={errorId}>{validationMessage}</div>
    </div>}

  </div>;
};
export const FormDropDownList = fieldRenderProps => {
  const {
    onChange,
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  // Define a local onChange handler to integrate with passed props
  const handleChange = event => {
    if(onChange) {
      onChange(event); // Call the passed onChange prop if available
    }
    // Additional actions can be performed here if necessary
  };

  return (
    <div className="fv-row mb-10">
      <label className="fs-6 fw-semibold form-label required">{label}</label>
      <DropDownList
        className="form-select form-select-lg form-select-solid"
        data-control="select2"
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        onChange={handleChange}
        {...others}  // Pass the remaining props to DropDownList
      />
      {showValidationMessage && (
        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
          <div id={errorId}>{validationMessage}</div>
        </div>
      )}
    </div>
  );
};

export const FormCheckbox = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    visited, infotype,
    modified, onValidChange,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';


  const [checked, setChecked] = React.useState(false);

  const [moblestatus, setmoblestatus] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => {
      setmoblestatus(window.innerWidth <= 768); // 모바일 뷰의 너비 임계값을 설정하세요
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const htmlMessage = infotype === "대표자" ? '<p style="text-align:left">삼성중공업 주식회사(이하 "삼성"이라 함)는 개인정보보호법, 정보통신망 이용촉진 및 정보 보호 등에 관한 법률 등 관련 법령상의 개인정보보호 규정을 준수하고있으며, 삼성은 협력사 대표의 개인정보를 수집 및 이용하고자 본 동의서를 받고 있습니다.</p><br><ul  style="text-align:left"><li>수집 목적: 협력사 정보 관리</li><li>수집 항목(필수): 대표자 성명(국문,영문), 대표자 생년월일, 대표자 및 임원의 당사 근무 여부, 대표자 휴대폰 번호</li><li>수집 항목(선택): 대표자의 경력</li><li>이용 기간: 당사와 거래종료 시까지</li></ul><br><p style="text-align:left">※ 귀하는 상기 개인정보 중 필수정보는 업체 등록을 위한 필수사항이므로 필수정보 수집에 동의를 하셔야만 업체 등록의 설정 및 유지가 가능합니다.</p>' :
    "임직원" ? '<p style="text-align:left">삼성중공업 주식회사(이하 "삼성"이라 함)는 개인정보보호법, 정보통신망 이용촉진 및 정보 보호 등에 관한 법률 등 관련 법령상의 개인정보보호 규정을 준수하고있으며, 삼성은 협력사 임원의 당사 근무여부 확인을 위한 개인정보를 수집 및 이용하고자 본 확인서를 받고 있습니다.</p>' :
      "";


  const showPrivacyPopup = () => {
    Swal.fire({
      title: '개인정보 수집 및 이용 동의서',
      html: htmlMessage,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '네, 동의합니다.',
      cancelButtonText: '동의하지 않습니다.',
      customClass: {
        popup: 'large-popup'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        fieldRenderProps.onValidChange(true);
        setChecked(true);
        Swal.fire('동의함!', '당신의 개인 정보가 처리될 것입니다.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setChecked(false);
        fieldRenderProps.onValidChange(false);
        Swal.fire('취소함', '당신의 개인 정보는 처리되지 않을 것입니다.', 'error');
      }
    });
  };

  return <FieldWrapper>
    <style>
      {moblestatus ? `
              .large-popup {
                width: 480px !important; font-size:10px;
              }
            `: `
              .large-popup {
                width: 800px !important;
              }
            `}

    </style>
    <div className={"k-d-flex k-align-items-center"} >
      <div className={'k-form-field-wrap'}>
        <Checkbox checked={checked} ariaDescribedBy={`${hintId} ${errorId}`} label={label} labelOptional={optional} valid={valid} id={id} disabled={false} labelClassName='k-checkbox-label' {...others} />
        <button
          className={"k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-mr-4"} type='button' style={{ marginLeft: 8, fontSize: 8 }}
          onClick={showPrivacyPopup}
        >전문보기</button>
      </div>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};

export const FormSwitch = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    optional,
    id,
    valid,
    disabled,
    hint,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper>
    <div className={"k-d-flex k-align-items-center"}>
      <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional} className='fs-6 fw-semibold'>
        {label}
      </Label>
      <div className={'k-form-field-wrap'} style={{ marginLeft: 8 }}>
        <Switch onLabel={"Yes"} offLabel={"No"} ref={editorRef} ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </div>
    </div>
  </FieldWrapper>;
};

export const FormCheckbox2 = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    visited,
    modified,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>

    <div className={"k-d-flex k-align-items-center"}>
      <div className={'k-form-field-wrap'}>
        <Checkbox ariaDescribedBy={`${hintId} ${errorId}`} style={{ display: "none" }} label={label} labelOptional={optional} valid={valid} id={id} disabled={disabled} labelClassName='k-form-label' {...others} />
      </div>
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};

export const FormUpload = fieldRenderProps => {
  const {
    value, onValidChange,
    id,
    optional,
    label,multiple,
    hint,
    validationMessage, defaultFiles,
    touched,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;

  
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';


  const setFormState = useSetRecoilState(formStateAtom2);
  const formStateReal = useRecoilValue(formStateAtom2);

  console.log(formStateReal[id])


  const onAdd = (e) => {
    const affectedFiles = e.affectedFiles;
    // fieldRenderProps.value가 배열이 아니면 빈 배열을 사용합니다.
    const currentFiles = Array.isArray(fieldRenderProps.value) ? fieldRenderProps.value : [];
    const updatedState = [...currentFiles]; // 현재 상태의 파일 목록을 복사합니다.

    affectedFiles.forEach((file) => {
      const fileObject = file.getRawFile();
      const fileType = file.extension;
      const reader = new FileReader();

      reader.onload = (loadEvent) => {
        const base64 = loadEvent.target.result;
        const updatedFile = {
          name: id === "준법" ? "준법서류" + fileType : file.name,
          base64: base64,
        };

        updatedState.push(updatedFile); // 새 파일을 업데이트된 상태에 추가합니다.

        // 모든 파일이 처리된 후 상태 업데이트
        if (updatedState.length === currentFiles.length + affectedFiles.length) {
          fieldRenderProps.onValidChange(true);
          fieldRenderProps.onChange({ value: updatedState }); // 업데이트된 파일 목록으로 상태를 업데이트합니다.
          setFormState((prevFormState) => ({
            ...prevFormState,
            [id]: updatedState,
          }));
        }
      };
      

      reader.readAsDataURL(fileObject);
    });
  };

  const onRemoveHandler = (event) => {
    const fileNameToRemove = event.affectedFiles[0].name; // 제거할 파일 이름
    const updatedFiles = value.filter((file) => file.name !== fileNameToRemove); // 제거할 파일을 제외한 새 파일 목록

    fieldRenderProps.onValidChange(updatedFiles.length > 0);
    fieldRenderProps.onChange({ value: updatedFiles }); // 새 파일 목록으로 상태 업데이트
    setFormState((prev) => ({ ...prev, [id]: updatedFiles }));
  };

  const uploadRef = React.createRef();

  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const display = fieldRenderProps.value && fieldRenderProps.value.length > 0 || isMobile || id === "준법" ? "none" : 'block';

  return <FieldWrapper>
    <Label id={labelId} editorId={id} optional={optional} className='k-form-label'>
      {label}
    </Label>
    <div className={'k-form-field-wrap'}>

      <LocalizationProvider language="ko-KR">
        <IntlProvider locale="kr">
          <Upload ref={uploadRef} defaultFiles={defaultFiles}
            // restrictions={{
            //     allowedExtensions: [".jpg", ".png", ".pdf", ".svg", ".jpeg"],
            //   }} 
            id={id} autoUpload={false} batch={false} showActionButtons={false} multiple={multiple} files={value}
            onAdd={onAdd}
            onRemove={onRemoveHandler} ariaDescribedBy={`${hintId} ${errorId}`} ariaLabelledBy={labelId} {...others} />

          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && <Error style={{ padding: '2rem 2.25rem' }} id={errorId}>{validationMessage}</Error>}
          <div
            style={{
              height: "10px",
            }}
          />
          <div>
            <ExternalDropZone uploadRef={uploadRef} />
          </div>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  </FieldWrapper>;
};

export const FormMultiSelect = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return <FieldWrapper style={wrapperStyle}>
    <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled} className='form-label required'>
      {label}
    </Label>
    <div className={'k-form-field-wrap'}>
      <MultiSelect ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} {...others} />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};

export const FormTextArea = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    disabled,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper >
    <Label editorId={id} editorValid={valid} optional={optional} className='form-label required'>{label}</Label>
    <div className={'k-form-field-wrap'}>
      <TextArea valid={valid} id={id} disabled={disabled} className='form-control form-control-lg form-control-solid' ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};

export const FormDatePicker = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    hintDirection,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
    <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled} className='k-form-label'>
      {label}
    </Label>
    <div className={'k-form-field-wrap'}>
      <DatePicker ariaLabelledBy={labelId} format={"yyyy년 MM월 dd일"} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
      {showHint && <Hint id={hintId} direction={hintDirection}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  </FieldWrapper>;
};
import React, { useState,useEffect } from 'react';
import { Link as RouterLink} from 'react-router-dom';
import axios from '../common/axios';
import Swal from 'sweetalert2';
import useDocumentTitle from '../common/useDocumentTitle';

const Nav = () => {
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [isMenuVisible2, setMenuVisible2] = useState(false);
    const [isMenuVisible3, setMenuVisible3] = useState(false);
	const [countData, setCountData] = useState({});
	const [checkM, setCheckM] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [translateValue1, setTranslateValue1] = useState({ x: 836, y: 74 });
	const [translateValue2, setTranslateValue2] = useState({ x: 932, y: 74 });
	const [translateValue3, setTranslateValue3] = useState({ x: 1032, y: 74 });

	useDocumentTitle("삼성중공업 - 협력업체포탈")

	useEffect(() => {
		function handleResize() {
		  setWindowWidth(window.innerWidth);
		}
	
		window.addEventListener('resize', handleResize);
	
		return () => {
		  window.removeEventListener('resize', handleResize);
		};
	  }, []);

	  useEffect(() => {
		const screenResolution = window.screen.width;

		const xValue1 = ((820/1920)*screenResolution * windowWidth) / screenResolution; 
		const xValue2 = ((910/1920)*screenResolution * windowWidth) / screenResolution; 
		const xValue3 = ((996/1920)*screenResolution * windowWidth) / screenResolution; 
		const yValue = 74; 
	  
		setTranslateValue1({ x: xValue1, y: yValue });
		setTranslateValue2({ x: xValue2, y: yValue });
		setTranslateValue3({ x: xValue3, y: yValue });
	  }, [windowWidth]);

	useEffect(() => {
		const fetchData = async () => {
		  try {
			  const res = await axios.get('/getcount'); 
			  const res2 = await axios.get('/checkM'); 
			  if(res.data.success && res2.data.success){
				setCountData(res.data.data);
				setCheckM(res2.data.data);
			  }
		   
		  } catch (error) {
			console.error('데이터 가져오기 중 오류 발생:', error);
		  }
		};
		fetchData();
	  }, []);
	
	
    let leaveTimeout;
    let leaveTimeout2;
    let leaveTimeout3;


	console.log(checkM);

    const handleMouseEnter = () => {
    clearTimeout(leaveTimeout); // 이전의 딜레이를 취소합니다.
    setMenuVisible(true);
    };

    const handleMouseLeave = () => {
    leaveTimeout = setTimeout(() => {
        setMenuVisible(false);
    }, 200); 
    };

	const handleMouseEnter2 = () => {
		clearTimeout(leaveTimeout2); // 이전의 딜레이를 취소합니다.
		setMenuVisible2(true);
		};
	
	const handleMouseLeave2 = () => {
	leaveTimeout2 = setTimeout(() => {
		setMenuVisible2(false);
	}, 200); 
	};

	const handleMouseEnter3 = () => {
		clearTimeout(leaveTimeout3); // 이전의 딜레이를 취소합니다.
		setMenuVisible3(true);
		};
	
	const handleMouseLeave3 = () => {
	leaveTimeout3 = setTimeout(() => {
		setMenuVisible3(false);
	}, 200); 
	};


	const [moblestatus, setmoblestatus] = useState(window.innerWidth <= 768); 

	useEffect(() => {
	 const handleResize = () => {
	   setmoblestatus(window.innerWidth <= 768); // 모바일 뷰의 너비 임계값을 설정하세요
	 };
   
	 window.addEventListener('resize', handleResize);
   
	 return () => {
	   window.removeEventListener('resize', handleResize);
	 };
   }, []);
        

   const [data, setData] = useState({});


   useEffect(() => {
	const fetchData = async () => {
	  try {
		const res = await axios.get('/checkPE'); 
		if(res.data.success){
		  setData(res.data);
		}
	   
	  } catch (error) {
		console.error('데이터 가져오기 중 오류 발생:', error);
	  }
	};
	fetchData();
  }, []);

  const handleClick = () => {
	if (data.status !== -1) {
		Swal.fire({
			icon: 'info',
			title: '알림',
			text: '평가를 이미 제출했습니다'
		});
	}
};
    return(

   <>
            <div className="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                <span className="menu-link">
                 <RouterLink  className="menu-title"to="/home" >Home</RouterLink>
                </span>
            </div>
            <div className="menu-item menu-here-bg me-0 me-lg-2">
			<span className="menu-link">
        {data.status === -1 ? (
            <RouterLink className="menu-title" to= "/make-evaluation" >
                사전 평가
            </RouterLink>
        ) : (
            <span className="menu-title" onClick={handleClick}>
                사전 평가
            </span>
        )}
    </span>
            </div>
            <div className="menu-item menu-here-bg me-0 me-lg-2">
                <span className="menu-link">
                    <span className="menu-title">정기 평가</span>
                </span>
            </div>
            <div className={isMenuVisible2 ?"menu-item here show menu-dropdown":"menu-item here"}   onClick={moblestatus ? () => setMenuVisible2(!isMenuVisible2) : undefined}
			onMouseEnter={!moblestatus ? handleMouseEnter2 : undefined}
			onMouseLeave={!moblestatus ? handleMouseLeave2 : undefined}
			style={{ position: "relative" }} // 부모 요소에 position: relative 추가
			>
                <span className="menu-link">
						<span className="menu-title">입찰 관리</span>
                      <span className="menu-arrow d-lg-none"></span>
                </span>


			<div className=
                { `menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 ${moblestatus?'px-lg-2 py-lg-4 w-lg-200px': 'px-lg-2 py-lg-4 w-lg-200px'} ${isMenuVisible2? 'show':""}`}
                    style={ moblestatus? {}:isMenuVisible2?
					{ zIndex: 107, position: "absolute", inset: "0px auto auto 0px", margin: 0, 
					left: 0,
					top:"100%",
					backgroundColor: "#ffffff",
					// transform: `translate(${translateValue1.x}px,${translateValue1.y}px)`, 
					display: "block", backgroundColor: "#ffffff" } 
					:{}}>
					<div className="menu-item">
							<RouterLink to="/log-sitemeeting"className="menu-link">
							<span className="menu-title">현장설명회</span>
							{countData && countData.sitemeeting &&countData.sitemeeting !==0?
							<span className="menu-badge">
								<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.sitemeeting}</span>
							</span>:<span></span>}
							</RouterLink>
					</div>
					<div className="menu-item">
							<RouterLink to="/log-safety" className="menu-link">
							<span className="menu-title">안전적격성 자료 제출</span>
							{countData && countData.safety &&countData.safety !==0?
							<span className="menu-badge">
								<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.safety}</span>
							</span>:<span></span>}
							</RouterLink>
					</div>
					<div className="menu-item">
							<RouterLink to="/log-bidding" className="menu-link">
							<span className="menu-title">입찰 이력</span>
							</RouterLink>
					</div>
				</div>
			</div>
            <div className={isMenuVisible ?"menu-item here show menu-dropdown":"menu-item here"}   onClick={moblestatus ? () => setMenuVisible(!isMenuVisible) : undefined}
			onMouseEnter={!moblestatus ? handleMouseEnter : undefined}
			onMouseLeave={!moblestatus ? handleMouseLeave : undefined}
			style={{ position: "relative" }} // 부모 요소에 position: relative 추가
			>
										<span className="menu-link">
											<span className="menu-title">계약 관리</span>
											<span className="menu-arrow d-lg-none"></span>
										</span>
										<div className=
                                        { `menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 ${moblestatus?'w-100 w-lg-900px w-xxl-950px': 'w-50 w-lg-400px w-xxl-450px'} ${isMenuVisible? 'show':""}`}
                                        style={ moblestatus? {}:isMenuVisible?
										{ zIndex: 107, position: "absolute", inset: "0px auto auto 0px", margin: 0, 
					left: 0,
					top:"100%",
					backgroundColor: "#ffffff",
					// transform: `translate(${translateValue1.x}px,${translateValue1.y}px)`, 
					display: "block", backgroundColor: "#ffffff" } 
										:{}}
                                        
									   >
											<div className="menu-active-bg pt-1 pb-3 px-3 py-lg-6 px-lg-6" data-kt-menu-dismiss="true">
												<div className="row">
													<div className="col-lg-12">
														<div className="row">
															<div className="col-lg-6 mb-3">
																<h4 className="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">계약</h4>
																<div className={`${moblestatus? "menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2":"menu-item p-0 m-0"}`}>
																	<RouterLink to="/log-contract"  className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
																		</span>
																		<span className="menu-title">계약 조회</span>
																	</RouterLink>
																</div>
																<div className="menu-item p-0 m-0">
																	<RouterLink to="/log-agreement"  className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
																		</span>
																		<span className="menu-title">작업합의서<br/>(신규계약 전)</span>
																		{countData && countData.agreement &&countData.agreement !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.agreement}</span>
																		</span>:<span></span>}
																	</RouterLink>
																</div>
																<div className="menu-item p-0 m-0">
																	<RouterLink to="/confirmContract" className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
																		</span>
																		<span className="menu-title">신규계약 검토</span>
																		{countData && countData.check &&countData.check !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.check}</span>
																		</span>:<span></span>}
																	</RouterLink>
																</div>
																<div className="menu-item p-0 m-0">
																	<RouterLink to= "/confirmCO"  className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
																		</span>
																		<span className="menu-title">변경계약 검토</span>
																		{countData && countData.check2 &&countData.check2 !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.check2}</span>
																		</span>:<span></span>}
																	</RouterLink>
																</div>
																<div className="menu-item p-0 m-0">
																	<RouterLink to="/log-signing" className="menu-link">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
																		</span>
																		<span className="menu-title">계약 전자서명 조회</span>
																		{countData && countData.signing &&countData.signing !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.signing}</span>
																		</span>:<span></span>}
																	</RouterLink>
																</div>

																<div className="menu-item p-0 m-0">
																<RouterLink  className="menu-link"to= "/log-bond" >
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
																		</span>
																		<span className="menu-title">보증보험 업로드</span>
																		{countData && countData.bond &&countData.bond !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.bond}</span>
																		</span>:<span></span>}
																</RouterLink>
																</div>
																<div className="menu-item p-0 m-0">
																<RouterLink  className="menu-link"to="/log-stamp">
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
																		</span>
																		<span className="menu-title">인지세 납부</span>
																		{countData && countData.paperTax &&countData.paperTax !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.paperTax}</span>
																		</span>:<span></span>}
																</RouterLink>
																</div>
																<div className="menu-item p-0 m-0">
																<RouterLink  className="menu-link"to= "/log-safety2" >
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
																		</span>
																		<span className="menu-title">정기 안전기술지도</span>
																		{countData && countData.safety2 &&countData.safety2 !==0?
																		<span className="menu-badge">
																			<span className="badge badge-light-danger badge-circle fw-bold fs-7">{countData.safety2}</span>
																		</span>:<span></span>}
																</RouterLink>
																</div>
															</div>
															<div className="col-lg-6 mb-3">
																<h4 className="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">기성</h4>
																<div className="menu-item p-0 m-0">
																	<RouterLink  className="menu-link"to= "/log-payment" >
																		<span className="menu-bullet">
																			<span className="bullet bullet-dot bg-gray-300i h-6px w-6px"></span>
																		</span>
																		<span className="menu-title">기성 조회 및 신청</span>
																	</RouterLink>
																</div>
															</div>
			
														</div>

													</div>

												</div>
											</div>
										</div>
            </div>

			{checkM && 
			<div className={isMenuVisible3 ?"menu-item show menu-dropdown":"menu-item"}   onClick={moblestatus ? () => setMenuVisible3(!isMenuVisible3) : undefined}
			onMouseEnter={!moblestatus ? handleMouseEnter3 : undefined}
			onMouseLeave={!moblestatus ? handleMouseLeave3 : undefined}
			style={{ position: "relative" }} // 부모 요소에 position: relative 추가
			
			>
                <span className="menu-link">
						<span className="menu-title">정보 업데이트</span>
                      <span className="menu-arrow d-lg-none"></span>
                </span>


			<div className=
                { `menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 ${moblestatus?'px-lg-2 py-lg-4 w-lg-200px': 'px-lg-2 py-lg-4 w-lg-200px'} ${isMenuVisible3? 'show':""}`}
                    style={ moblestatus? {}:isMenuVisible3?
					{ zIndex: 107, position: "absolute", inset: "0px auto auto 0px", margin: 0, 
					left: 0,
					top:"100%",
					backgroundColor: "#ffffff",
					// transform: `translate(${translateValue1.x}px,${translateValue1.y}px)`, 
					display: "block", backgroundColor: "#ffffff" }
					:{}}>
					<div className="menu-item">
					<RouterLink to="/updateinfo"className="menu-link">
							<span className="menu-title">기본 정보 업데이트</span>
							</RouterLink>
					</div>
					<div className="menu-item">
							<RouterLink to="/updatecontact"className="menu-link">
							<span className="menu-title">담당자 정보 업데이트</span>
							</RouterLink>
					</div>
					<div className="menu-item">
							<RouterLink to="/updatefile" className="menu-link">
							<span className="menu-title">관련 서류 제출</span>
							</RouterLink>
					</div>
				</div>
			</div>}



            </>
)};

export default Nav;